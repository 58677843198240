import { create } from 'zustand';
import { Api } from '../../../lib/api/axios';
import useAdminAuth from '../admin/useAdminAuth';

const useInvestmentListing = create((set, get) => ({
  data: [],
  investmentList: [],
  profileInvestment: [],
  detailProfile: null,
  investmentPackageListing: [],
  resetState: () => {
    set(() => ({ detailProfile: null, profileInvestment: [] }));
  },
  loading: false,
  getInvestmentProfile: async (id, coin) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.get(`/cms/investment/member/${id}/${coin}`);
      set(() => ({
        profileInvestment: res.data,
      }));
      return;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  getInvestmentList: async (id) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.get(`/cms/investment/getInvestmentList/${id}`);
      console.log("result", res.data);
      set(() => ({
        investmentList: res.data,
      }));
      return;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  getDetailProfile: async (id, filter) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.get(`/cms/investment/${filter}/${id}`);
      set(() => ({
        detailProfile: res.data,
      }));
      return;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  playInvestment: async (id, controller) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.post(`/cms/investment/play/${id}`);
      await get().getInvestmentPackageListing(
        controller.search,
        controller.page,
        controller.perPage
      );
      //return res.status;
      return res;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  cancelInvestment: async (id, controller) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.post(`/cms/investment/stop/${id}`);
      await get().getInvestmentPackageListing(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res.status;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  deleteInvestment: async (id, controller) => {
    try {
      set(() => ({ loading: true }));
      const res = await Api.delete(`/cms/investment/${id}`);
      await get().getInvestmentPackageListing(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    } finally {
      set(() => ({ loading: false }));
    }
  },
  getInvestmentPackageListing: async (
    searchTerm = '',
    page = 1,
    perPage = 10,
    sort = '',
    sortType = ''
  ) => {
    try {
      const res = await Api.get(
        `/cms/investment?handler=${useAdminAuth.getState().admin?.role === 'master' ||
          useAdminAuth.getState().admin?.role === 'admin'
          ? ''
          : useAdminAuth.getState().admin?._id
        }&searchTerm=${searchTerm}&page=${page}&limit=${perPage}&sort=${sort}&sortType=${sortType}`
      );
      set(() => ({
        investmentPackageListing: res.data?.docs,
      }));
      return res.data;
    } catch (error) {
      return error?.response?.data;
    }
  },
  updateInvestPackageListing: async (payload, id, controller) => {
    try {
      const res = await Api.patch(`/cms/investment/${id}`, payload);
      await get().getInvestmentPackageListing(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res.status;
    } catch (error) {
      return error?.response?.data;
    }
  },
  instanProfit: async (id, payload, controller) => {
    try {
      console.log(controller);
      const res = await Api.post(`/cms/investment/instant/${id}`, payload);
      await get().getInvestmentPackageListing(
        controller.search,
        controller.page,
        controller.perPage
      );
      return res;
    } catch (error) {
      return error?.response?.data;
    }
  },
}));

export default useInvestmentListing;
