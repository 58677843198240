/* eslint-disable no-unused-vars */
import React from 'react';
import { ReactComponent as Polkadot } from '../../icon/polkadot-logo.svg';
import { ReactComponent as Huobi } from '../../icon/huobi-logo.svg';
import { ReactComponent as Ethereum } from '../../icon/ethereum-logo.svg';
import { ReactComponent as Bitcoin } from '../../icon/bitcoin-logo.svg';
import { ReactComponent as Ripple } from '../../icon/xrp-logo.svg';
import {
  HiOutlineCreditCard,
  HiOutlineCash,
  HiOutlineDocumentSearch,
  HiOutlineTrendingUp,
  HiOutlineMail,
  HiOutlineLockClosed,
  HiOutlineLibrary,
  HiOutlineHome,
  HiOutlineChat,
} from 'react-icons/hi';

export const userMenu = [
  {
    key: 'dashboard',
    menu: 'Home',
    label: 'Dashboard',
    icon: null,
    subMenuItems: [],
    menuItems: [
      {
        key: 'dashboard',
        label: 'Dashboard',
        icon: <HiOutlineHome />,
        path: '/dashboard',
      },
    ],
  },
  {
    key: 'investment',
    menu: 'Investment',
    label: 'Crypto',
    icon: null,
    is2level: true,
    subMenuItems: [
    
    ],
    menuItems: [
      {
        key: 'crypto',
        label: 'Crypto',
        path: '',
        ac: "crypto",
        subItems : [
          {
            key: 'bitcoin',
            label: 'Bitcoin',
            path: '/dashboard/bitcoin',
            ac: "bitcoin",
          },
          {
            key: 'eth',
            label: 'Ethereum',
            path: '/dashboard/ethereum',
            ac: "eth",
          },
          {
            key: 'pol',
            label: 'Polkadot',
            path: '/dashboard/polkadot',
            ac: "pol",
          },
          {
            key: 'Houbitoken',
            label: 'Houbitoken',
            path: '/dashboard/houbitoken',
            ac: "Houbitoken",
          },
          {
            key: 'Ripple',
            label: 'Ripple',
            path: '/dashboard/ripple',
            ac: "Ripple",
          },
          {
            key: 'doge',
            label: 'Dogecoin',
            path: '/dashboard/dogecoin',
            ac: "doge",
          },
          {
            key: 'bnb',
            label: 'Binancecoin',
            path: '/dashboard/binance',
            ac: "bnb",
          },
          {
            key: 'trx',
            label: 'Tron',
            path: '/dashboard/tron',
            ac: "trx",
          },
          {
            key: 'sol',
            label: 'Solana',
            path: '/dashboard/solana',
            ac: "sol",
          },
          {
            key: 'usdt',
            label: 'USDT',
            path: '/dashboard/usdt',
            ac: "usdt",
          }
        ]
      },
      {
        key: 'shareIndex',
        label: 'Share Index',
        path: '',
        ac: "shareIndex",
        subItems : [
          {
            key: 'sp500',
            label: 'SP 500',
            path: '/dashboard/sp500',
            ac: "sp500",
          },
          {
            key: 'dxy',
            label: 'US Dollar Index',
            path: '/dashboard/usdollarindex',
            ac: "dxy",
          },
          {
            key: 'vix',
            label: 'Volatility Index',
            path: '/dashboard/volatility',
            ac: "vix",
          },
          {
            key: 'dax',
            label: 'DAX Index',
            path: '/dashboard/dax',
            ac: "dax",
          },
          {
            key: 'hsi',
            label: 'Hang Seng Index',
            path: '/dashboard/hsi',
            ac: "hsi",
          },
          {
            key: 'cac40',
            label: 'CAC40 Index',
            path: '/dashboard/cac40',
            ac: "cac40",
          },
          {
            key: 'nikkei',
            label: 'Nikkei Index',
            path: '/dashboard/nikkei',
            ac: "nikkei",
          },
          {
            key: 'ndx',
            label: 'Nasdaq Index',
            path: '/dashboard/nasdaq',
            ac: "ndx",
          },
        ]
      },
    ],
  },
  {
    key: 'finance',
    menu: 'Finance',
    label: 'Finance',
    icon: null,
    subMenuItems: [],
    menuItems: [
      {
        key: 'Withdrawal',
        label: 'Withdrawal',
        icon: <HiOutlineCash />,
        path: '/dashboard/withdraw',
      },
      {
        key: 'Withdrawal History',
        label: 'Withdrawal History',
        icon: <HiOutlineDocumentSearch />,
        path: '/dashboard/withdraw-history',
      },
      // {
      //   key: 'Profit/Loss History',
      //   label: 'Profit/Loss History',
      //   icon: <HiOutlineTrendingUp />,
      //   path: '/dashboard/profit-loss-history',
      // },
      {
        key: 'Deposit History',
        label: 'Deposit History',
        icon: <HiOutlineCreditCard />,
        path: '/dashboard/deposit-history',
      },
      {
        key: 'Trading History',
        label: 'Trading History',
        icon: <HiOutlineCreditCard />,
        path: '/dashboard/profit-loss',
      },
    ],
  },
  // {
  //   key: 'account',
  //   menu: 'Account',
  //   label: 'Account',
  //   icon: null,
  //   subMenuItems: [],
  //   menuItems: [
  //     // {
  //     //   key: 'Email',
  //     //   label: 'Email',
  //     //   icon: <HiOutlineMail />,
  //     //   path: '/dashboard/email',
  //     // },
  //     // {
  //     //   key: 'Password',
  //     //   label: 'Password',
  //     //   icon: <HiOutlineLockClosed />,
  //     //   path: '/dashboard/password',
  //     // },
  //     // {
  //     //   key: 'Bank Information',
  //     //   label: 'Bank Information',
  //     //   icon: <HiOutlineLibrary />,
  //     //   path: '/dashboard/bank-information',
  //     // },
  //     // {
  //     //   key: 'In App Chat',
  //     //   label: 'In App Chat',
  //     //   icon: <HiOutlineChat />,
  //     //   path: '/dashboard/in-app-chat',
  //     // },
  //   ],
  // },
];
